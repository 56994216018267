@import url('./static/fonts/pretendardvariable-dynamic-subset.css');

html,
body {
  height: 100%;
}

html {
  overflow-y: auto;
}

body {
  margin: 0;
  font-family: 'Pretendard Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
    'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

ol,
ul {
  list-style: none;
  padding: 0 0 0 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 1200px) {
  .container {
    width: 1260px !important;
  }
}
@media (min-width: 992px) {
  .container {
    width: 1260px !important;
  }
}
@media (min-width: 768px) {
  .container {
    width: 1260px !important;
  }
}
@media (min-width: 576px) {
  .container {
    width: 1260px !important;
  }
}

.container {
  width: 1260px !important;
}
.thumbnail {
  width: 1260px !important;
}

a {
  color: inherit;
  text-decoration: none;
}

h2,
p {
  margin-top: 0;
  margin-bottom: 0;
}

.page-header {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

/* iOS issue */
h3 {
  appearance: auto;
}

select option {
  color: #000;
}
