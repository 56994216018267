.App {
  height: 100%;
  overflow: hidden;
}

.modal-dialog {
  max-width: 680px !important;
  min-width: 680px;
}

.modal-content {
  border-radius: 10px !important;
  max-width: 680px;
}

@media screen and (max-width: 768px) {
  .modal-dialog {
    min-width: auto;
  }
}
